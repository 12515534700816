/* eslint-disable react/prop-types */
import loadable from '@loadable/component';
import classnames from 'classnames';
import { FormattedMessage, FormattedHTMLMessage, useIntl } from 'react-intl';
import React, { useEffect, useState, useMemo, useContext, useRef, useCallback } from 'react';
import { Link, useLocation, useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useFrontload } from 'react-frontload';
//todo head: search term from router, locale, config, staticApi.getCategoriesData
import { DRAWER_DIRECTION_LTR } from '../Drawer/Drawer.definitions';
import Button, { PRIMARY } from '../Button/Button';

import constructURL from '../../utils/constructURL';
import { getValidResponseBody, staticApi, searchApi, imageApi } from '../../api';

import useMediaQuery from '../../utils/useMediaQuery';

import { ReactComponent as ChevronDownIcon } from '../../images/icons/chevron_down.svg';
import { ReactComponent as ChevronUpIcon } from '../../images/icons/chevron_up.svg';
import { ReactComponent as FiltersIcon } from '../../images/icons/filters.svg';
import { ReactComponent as FilterIcon } from '../../images/icons/filter.svg';

import styles from './SearchResultsPage.module.scss';

import getSearchPayload, {
  DEFAULT_NUMBER_PRODUCTS_PER_PAGE,
  SORTING_OPTIONS_NEW,
  SORTING_OPTIONS_PRICE_ASC,
  SORTING_OPTIONS_PRICE_DESC,
  SORTING_OPTIONS_RELEVANCE,
} from '../../utils/getSearchPayload';
import HeadingH1 from './HeadingH1';
import useFacetsFetcher from './useFacetsFetcher';
import { clipTextEllipsis } from '../../utils/textEllipsis';
import useClientEffect from '../../utils/useClientEffect';
import { SSRContext } from '../../utils/SSRContext';

import { VIEW_OPTIONS_COLUMNS, VIEW_OPTIONS_TILES } from './SRP.definitions';
import loadVisibleComponent from '../../utils/loadVisibleComponent';
import SearchBreadcrumb from '../SearchBreadcrumb/SearchBreadcrumb';
import { useAuth0Wrapper } from '../../utils/auth0';
import SearchRedirectMessage from './SearchRedirectMessage';
import { setSearchProducts } from '../../state/productSearch/productSearch';
import CategoryContentPageLink from '../CategoryContentPageLink';
import { SRPWrapper } from '../SRPWrapper/SRPWrapper';
import { getPagePath } from '../../utils/appRoutes';
import { ARD_CATEGORY_SEARCH } from '../../utils/appRoutes.definitions';
import ImageSideBar from './ImageSideBar';
import { constructSRPCanonical, createPaginatedCanonicalUrl } from './constructSRPCanonical';
import { useNotificationOnApiError } from '../../utils/useNotificationOnApiError';
import { ImageCropSearchContext } from '../ImageCropSearch/ImageCropSearchContext';
import dataLayerPush from '../../utils/dataLayerPush';
import TopRelevantCategories from '../TopRelevantCategories';
import { SwitchableFeature } from '../../features/SwitchableFeature/SwitchableFeature';
import { TOP_RELEVANT_CATEGORIES } from '../../features/SwitchableFeature/SwitchableFeature.definitions';
import useNotifications from '../NotificationPopup/useNotifications';
import { useCookies } from 'react-cookie';

const ProductsList = loadable(() => import('Components/ProductsList/ProductsList'));
const RecentlyViewedProducts = loadVisibleComponent(() => import('../RecentlyViewedProducts/RecentlyViewedProducts'));
const Pagination = loadVisibleComponent(() => import('../Pagination/Pagination'));
const StickyHeader = loadable(() => import('../StickyHeader/StickyHeader'));
const Footer = loadVisibleComponent(() => import('../Footer/Footer'));
const NewsletterSection = loadVisibleComponent(() => import('../NewsletterSection/NewsletterSection'));
const FiltersHomeDrawer = loadable(() => import('../FiltersHomeDrawer/FiltersHomeDrawer'));
const PageSizeDropdown = loadVisibleComponent(() => import('../PageSizeDropdown/PageSizeDropdown'));
const NoResultsPage = loadable(() => import('../NoResultsPage/NoResultsPage'));
//todo: There is a flickecking on tranition between SRP and category page
//todo: can be avoidded loading ProductPlaceholder only on client side
//todo: requires loading ProductPlaceholder modules as separate chunk ONLY on client side
// const ProductPlaceholder = loadable(() => import('../ProductPlaceholder/ProductPlaceholder'));
const ViewOptions = loadable(() => import('./ViewOptions'));
const FacetsSidebar = loadVisibleComponent(() => import('../FacetsSidebar/FacetsSidebar'));

/**
 * Redirect from /zoek/?category=n to /n/products/
 * @param {*} replace - exported function from useLocation hook
 * @returns undefined
 */
const CCPRedirect = (replace, location) => {
  if (!location.search) return;
  const searchParams = new URLSearchParams(location.search);
  if (searchParams.has('category')) {
    const categories = searchParams.get('category').split('|');
    searchParams.delete('category');
    const targetURLObj = new URL(process.env.REACT_APP_BASE_URL + getPagePath(ARD_CATEGORY_SEARCH, categories));
    targetURLObj.search = searchParams.toString();
    const targetUrl = targetURLObj.toString();
    replace(targetUrl);
  }
};

const filterTruthyProperties = (obj) => {
  return Object.fromEntries(
    Object.entries(obj).filter(([_, value]) => {
      if (Array.isArray(value)) {
        // Check if the array has any non-nullish value
        return value.some((item) => item != null);
      }
      // Keep properties with truthy values
      return Boolean(value);
    })
  );
};

const SearchResultsPage = () => {
  const { sendNotification } = useNotifications();
  const dispatch = useDispatch();
  const config = useSelector(({ config }) => config);
  const intl = useSelector(({ intl }) => intl);
  const intl2 = useIntl();

  const { validateResponse, networkErrorNotification } = useNotificationOnApiError();
  const imageSearch = useSelector((state) => state.productSearch.imageUrl);
  const { searchProducts } = useSelector(({ productSearch }) => productSearch);
  const location = useLocation();
  const { tokenClaims } = useAuth0Wrapper();
  const authToken = tokenClaims?.__raw;
  const [cookies, setCookie, removeCookie] = useCookies(['search_filters_applied', 'search_query']);

  const sendSearchRequestEvent = useCallback(
    (searchTerm, isDesktopSSR, searchData, filtersApplied) => {
      const query = searchTerm;
      const expDate = new Date();
      expDate.setMonth(expDate.getMonth() + 1);

      setCookie('search_filters_applied', filtersApplied, {
        expires: expDate,
        path: '/',
      });

      setCookie('search_query', query, {
        expires: expDate,
        path: '/',
      });

      setCookie('search_id', searchData?.search_id, {
        expires: expDate,
        path: '/',
      });

      dataLayerPush({
        event: 'search_request',
        query,
        result_count: searchData?.hits?.hits?.length,
        result_total: searchData?.hits?.total?.value,
        filters_applied: JSON.stringify(filtersApplied || {}),
        search_id: searchData?.search_id,
      });
    },
    [setCookie]
  );

  const { search, hash } = location;

  const { categoryPath, uberfilter } = useParams();

  const categoriesParam = categoryPath || '';
  const categoriesParamArray = categoriesParam.split('/').filter(Boolean);
  const rootCategory = categoriesParamArray[0];

  const { replace } = useHistory();
  const { isDesktopSSR } = useContext(SSRContext);
  const { imageCropData } = useContext(ImageCropSearchContext);
  CCPRedirect(replace, location);
  const { isGreaterOrEqualTo, BREAKPOINTS } = useMediaQuery(isDesktopSSR);
  //todo: this one is not used anywhere. One assumtion: was used to force component update
  const [loadFeatures, setLoadFeatures] = useState(0);
  const isFashionCategory = () => rootCategory === 'kleding' || rootCategory === 'clothing';
  const [isFashionView, setFashionView] = useState(isFashionCategory());

  const queryStringParams = new URLSearchParams(search.replace(/\|/g, '%7C'));
  const brandsParam = queryStringParams.get('brand') || '';
  const colorsParam = queryStringParams.get('color') || '';
  const gendersParam = queryStringParams.get('gender') || '';
  const deliveryParam = queryStringParams.get('delivery') || '';
  const maxProductsPerPage = queryStringParams.get('pageSize') || DEFAULT_NUMBER_PRODUCTS_PER_PAGE;
  const modelsParam = queryStringParams.get('model') || '';
  const page = queryStringParams.get('page') || 1;
  const priceFromURLParam = queryStringParams.get('priceFrom');
  const priceFrom =
    typeof priceFromURLParam === 'string' ? parseInt(queryStringParams.get('priceFrom'), 10) : priceFromURLParam;

  const priceToURLParam = queryStringParams.get('priceTo');
  const priceTo = typeof priceToURLParam === 'string' ? parseInt(priceToURLParam, 10) : priceToURLParam;

  const searchTerm = queryStringParams.get('q') || '';
  const selectedProductParam = queryStringParams.get('sel') || '';
  const shopsParam = queryStringParams.get('webshop') || '';
  const sizesParam = queryStringParams.get('size') || '';
  let urlSortingOption = queryStringParams.get('sortBy');
  const urlViewOption = queryStringParams.get('view');
  const isDesktop = isGreaterOrEqualTo(BREAKPOINTS.EXTRA_LARGE);
  const isTablet = isGreaterOrEqualTo(BREAKPOINTS.MEDIUM);
  const pageSize = parseInt(maxProductsPerPage, 10);
  const [imageProducts, setImageProducts] = useState([]);
  const [isLoadImageProduct, setIsLoadImageProduct] = useState(false);
  const [showMobileCategoryLink, setShowMobileCategoryLink] = useState(false);

  useEffect(() => {
    window.scrollTo({ behavior: 'smooth', left: 0, top: 0 });
  }, [
    brandsParam,
    colorsParam,
    gendersParam,
    deliveryParam,
    maxProductsPerPage,
    modelsParam,
    page,
    priceFrom,
    priceTo,
    searchTerm,
    shopsParam,
    sizesParam,
    urlSortingOption,
    urlViewOption,
  ]);

  //todo: extract filters handling
  let activeFilters = [
    //...categoriesParamArray.map((item, idx) => [`${'sub'.repeat(idx)}category`, item]),
    ...Array.from(queryStringParams.entries()).filter(
      ([key]) => !['q', 'page', 'pageSize', 'sel', 'sortBy', 'view', 'clk'].includes(key)
    ),
  ];

  const GETParamsToSkip = [
    'brands',
    'colors',
    'genders',
    'pageSize',
    'page',
    'priceFrom',
    'priceTo',
    'q',
    'sel',
    'shops',
    'sizes',
    'sortBy',
    'view',
    'webshop',
    'clk',
  ];
  const filtersSearch = search.replace(/\&*sel\=[a-zA-Z0-9]{32}($|\&)/, '').replace('?', '');
  const featuresFilter = useMemo(
    () =>
      Array.from(queryStringParams.entries())
        .filter(([key]) => !GETParamsToSkip.includes(key))
        .map(([key, value]) => ({ key, values: value.split('|').filter(Boolean) })),
    [filtersSearch]
  );

  if (!urlSortingOption) {
    urlSortingOption = SORTING_OPTIONS_RELEVANCE;
  }

  const brandsFilter = brandsParam ? brandsParam.split('|') : [];
  const colorsFilter = colorsParam ? colorsParam.split('|') : [];
  const gendersFilter = gendersParam ? gendersParam.split('|') : [];
  const deliveryFilter = deliveryParam ? deliveryParam.split('|') : [];
  const modelsFilter = modelsParam ? modelsParam.split('|') : [];
  const shopsFilter = shopsParam ? shopsParam.split('|') : [];
  const sizesFilter = sizesParam ? sizesParam.split('|') : [];

  const searchParams = {
    brandsFilter,
    colorsFilter,
    currentPage: page,
    categoryFilter: categoriesParamArray,
    featuresFilter,
    gendersFilter,
    modelsFilter,
    deliveryFilter,
    priceFrom,
    priceTo,
    searchTerm,
    shopsFilter,
    sizesFilter,
    size: maxProductsPerPage,
    sort: urlSortingOption,
    uberfilter,
    wpCategory: categoriesParamArray?.[categoriesParamArray.length - 1] || undefined,
  };

  const searchPayload = getSearchPayload(searchParams);

  const headers = {};
  if (authToken) {
    headers.Authorization = `Bearer ${authToken}`;
  }

  const options = {
    headers: headers,
    method: 'POST',
    body: JSON.stringify(searchPayload),
    mobile: !isDesktop,
  };

  // const { data: categoryPageData, setData: setCategoryPageData, frontloadMeta: categoryPageReqMeta } = useFrontload(
  //   'categories-seo-data',
  //   async ({ staticApi }) => ({
  //   })
  // );
  //const { data: searchItems, setData: setSearchData } = useFrontload('searchdata', async ({ searchApi }) => ({
  const categoriesJSONPath = categoriesParamArray.join('.');
  const { data: SRPData, setData: setSRPData, frontloadMeta: SRPReqMeta } = useFrontload(
    'searchdata',
    async ({ searchApi, staticApi }) => {
      const [searchData, categoriesSEOData] = await Promise.all([
        searchApi.getSearchData(options),
        !!categoriesJSONPath ? staticApi.getCategories2Data(categoriesJSONPath) : null,
      ]);
      searchErrorRef.current = !searchData?.ok;

      return { searchData, categoriesSEOData };
    }
  );
  const searchErrorRef = useRef(SRPReqMeta?.error || SRPData?.searchData?.ok === false);
  const searchError = searchErrorRef?.current;
  //Get SEO data for categories from path
  const categoriesRequestData = getValidResponseBody(SRPData?.categoriesSEOData);
  const categoryData =
    SRPData?.categoriesSEOData?.ok && categoriesRequestData && categoriesParam ? categoriesRequestData : [];
  //Get requested categories names
  const categoryFilter = categoriesRequestData?.map(({ name }) => name) || [];

  const facetsDependencies = Object.keys(searchParams).reduce((fd, key) => {
    if (!['size', 'currentPage', 'sort'].includes(key)) return { ...fd, [key]: searchParams[key] };
    else return fd;
  }, {});
  const productsDependenciesString = JSON.stringify(searchParams);
  const facetsDependenciesString = JSON.stringify(facetsDependencies);
  const filtersDrawerOpened = hash.includes('filters');
  const featuresData = useFacetsFetcher(facetsDependenciesString, filtersDrawerOpened, isDesktop, options);
  const searchData = getValidResponseBody(SRPData?.searchData);
  const productVideosData = searchData?.videos;
  useEffect(() => {
    setImageProducts([]);
    if (imageSearch && imageSearch.id && imageCropData) {
      (async () => {
        try {
          setIsLoadImageProduct(true);
          const imageCropOptions = imageCropData ? imageCropData : {};
          const options = {
            headers: { ...headers, Accepts: '*/*', 'Content-Type': 'application/json' },
            method: 'POST',
            body: JSON.stringify({ id: imageSearch.id, ...imageCropOptions }),
          };
          const searchData = await imageApi.getImageSearchResults(options);
          if (searchData?.status !== 200) {
            sendNotification(intl2.formatMessage({ id: 'common.error.api' }));
            setImageProducts([]);
            return;
          }
          const searchDataIds = getValidResponseBody(searchData)?.results?.map((item) => item[0]);
          if (searchDataIds.length) {
            const productItem = {
              method: 'post',
              headers: headers,
              body: JSON.stringify({ ids: searchDataIds }),
            };

            const productsResponse = await searchApi.getWishlistProfile(productItem);
            const productsResponseBody = getValidResponseBody(productsResponse);
            if (productsResponseBody.length) {
              setImageProducts(productsResponseBody);
            }
          }
        } finally {
          setIsLoadImageProduct(false);
        }
      })();
    }
  }, [imageSearch, imageCropData]);

  //Products are rendered only 1 time on server side, no need to update them on client side again
  //useClientEffect skips first useEffect call on client side
  useClientEffect(() => {
    (async () => {
      try {
        const searchData = await searchApi.getSearchData(options);
        const searchDataValidated = validateResponse(searchData);
        setFashionView(isFashionCategory());
        setSRPData((data) => ({ ...data, searchData: searchDataValidated }));
        searchErrorRef.current = !searchData?.ok;
      } catch (e) {
        //todo: catch error from initial frontload as well
        networkErrorNotification();
        console.error(e.message);
      }
    })();
  }, [productsDependenciesString]);

  useEffect(() => {
    if (!searchData?.hits?.hits?.length) return;
    const filtersApplied = filterTruthyProperties(searchPayload?.payload?.filters);

    sendSearchRequestEvent(searchTerm, isDesktopSSR, searchData, filtersApplied);
  }, [SRPData?.searchData, sendSearchRequestEvent]);

  useClientEffect(() => {
    (async () => {
      if (categoriesJSONPath) {
        const categoriesSEOData = await staticApi.getCategories2Data(categoriesJSONPath);
        setSRPData((data) => ({ ...data, categoriesSEOData }));
      } else {
        setSRPData((data) => ({ ...data, categoriesSEOData: null }));
      }
    })();
  }, [categoriesJSONPath]);

  useEffect(() => {
    if (searchData?.hits && JSON.stringify(searchData.hits) !== JSON.stringify(searchProducts)) {
      dispatch(setSearchProducts(searchData.hits));
    }
  }, [searchData?.hits]);

  const sortingOption = [
    SORTING_OPTIONS_RELEVANCE,
    SORTING_OPTIONS_PRICE_ASC,
    SORTING_OPTIONS_PRICE_DESC,
    SORTING_OPTIONS_NEW,
  ].includes(urlSortingOption)
    ? urlSortingOption
    : undefined;

  const defaultView = isDesktop ? VIEW_OPTIONS_TILES : VIEW_OPTIONS_COLUMNS;
  const viewOption = [VIEW_OPTIONS_COLUMNS, VIEW_OPTIONS_TILES].includes(urlViewOption) ? urlViewOption : defaultView;

  const numberTotalProducts = searchData && searchData?.hits?.total && searchData?.hits?.total?.value;

  const products =
    searchData &&
    searchData?.hits?.hits.length > 0 &&
    searchData?.hits?.hits.map((hit) => ({ ...hit._source, id: hit._id }));

  const otherFeatures = !featuresData
    ? []
    : Object.keys(featuresData)
        .filter(
          (prop) =>
            prop.startsWith('feature::') && featuresData[prop].nested_features.filter_feature.feature.buckets.length > 0
        )
        .sort((a, b) => (featuresData[a].wpIndex < featuresData[b].wpIndex ? -1 : 1))
        .map((prop) => featuresData[prop]);

  //searchData?.canonical param is present in search api response only when parameters are presented as id's
  //at this point this is the case only with perma search links like /n/some-text/435435+664456
  const canonicalUrl = constructSRPCanonical(searchData?.canonical, categoryPath, page, brandsFilter);
  const lastSubcategory = categoryFilter?.slice(-1)[0];
  //todo: return back if needed
  // const lastSubCategoryData = categoriesRequestData && categoriesRequestData[categoriesRequestData.length - 1];
  const totalPages = Math.ceil((numberTotalProducts > 10000 ? 10000 : numberTotalProducts) / maxProductsPerPage);
  //todo: return back if needed
  // const metaTitle = lastSubCategoryData?.meta?.yoast_wpseo_title?.replace(
  //   '%%numberProductsCategory%%',
  //   numberTotalProducts
  // );
  // const metaDescription = lastSubCategoryData?.meta?.yoast_wpseo_metadesc.replace(
  //   '%%numberProductsCategory%%',
  //   numberTotalProducts
  // );

  const categoryPageTitleNum = (!!categoriesParam && page > 1 && `P.${page} `) || '';
  //todo: avoid using intl.messages
  const categoryPageTitle =
    intl?.messages &&
    !!categoriesParam &&
    categoryPageTitleNum +
      intl.messages['search.title']
        .replace(/\{category\}/g, lastSubcategory)
        .replace(/\{website\}/g, config.websiteName);

  if (searchData && !searchData?.hits?.hits?.length && page > 1 && search.split('&').length === 1) {
    replace(constructURL(location, { ignoreExistingQueryString: true }));
  }

  const searchRedirect = searchData?.warnings?.find(({ type }) => type === 'searchRedirect');
  const noResults = !!searchError || (searchData?.hits?.hits && searchData.hits.hits.length === 0);
  const searchTermOverride = searchData?.page_title?.trim();
  const capitalise = (text) => text[0].toUpperCase() + text.slice(1);
  const searchTermTitle = (searchTermOverride && capitalise(searchTermOverride)) || searchTerm;

  return (
    <React.Fragment>
      {!categoriesParam && (
        <Helmet>
          <title>{clipTextEllipsis(searchTermTitle, 60)}</title>
        </Helmet>
      )}
      <Helmet>
        <meta property="og:url" content={canonicalUrl} />
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
      {!!categoryPageTitle && (
        <Helmet>
          <title>{clipTextEllipsis(categoryPageTitle, 60)}</title>
        </Helmet>
      )}
      {/* todo: return back if needed */}
      {/* {!!categoriesParam && lastSubCategoryData?.meta?.yoast_wpseo_title && (
        <Helmet>
          <meta name="twitter:title" content={clipTextEllipsis(metaTitle, 70)} />
          <meta property="og:title" content={clipTextEllipsis(metaTitle, 60)} />
          <meta name="description" content={metaDescription} />
          <meta name="twitter:description" content={clipTextEllipsis(metaDescription, 200)} />
          <meta property="og:description" content={clipTextEllipsis(metaDescription, 160)} />
        </Helmet>
      )} */}
      <StickyHeader
        activeFilters={activeFilters}
        filters={searchPayload?.payload?.filters}
        searchTerm={searchTerm}
        selectedCategoriesData={categoriesRequestData}
        isSearchPage={true}
        categoriesInUrl={categoriesParamArray}
      />
      <div
        className={classnames(styles.root, 'container', {
          [styles.hasFilters]: !!activeFilters?.length,
        })}
      >
        <div className={classnames(styles.mainRow, 'row')}>
          {!!noResults && (
            <NoResultsPage
              spellingCorrections={searchData?.spellingCorrections}
              categoryData={categoryData}
              categoryFilter={categoryFilter}
              categoriesParamArray={categoriesParamArray}
              searchTerm={searchTerm}
            />
          )}
          {!noResults && (!searchData || !!searchData?.hits?.hits?.length) && (
            <>
              <aside
                className={classnames(styles.filters, 'col-12-xs col-3-xl ', {
                  ['col-2-xxl']: !imageSearch,
                  [styles.customFilters]: imageSearch,
                })}
                data-testid="SRPfilters"
              >
                {!imageSearch && featuresData && SRPData?.searchData?.ok && (
                  <FacetsSidebar
                    featuresData={featuresData}
                    categoriesParamArray={categoriesParamArray}
                    config={config}
                    intl={intl}
                    searchPayload={searchPayload}
                    priceFrom={priceFrom}
                    priceTo={priceTo}
                    brandsFilter={brandsFilter}
                    shopsFilter={shopsFilter}
                    colorsFilter={colorsFilter}
                    sizesFilter={sizesFilter}
                    modelsFilter={modelsFilter}
                    featuresFilter={featuresFilter}
                    gendersFilter={gendersFilter}
                    otherFeatures={otherFeatures}
                    deliveryFilter={deliveryFilter}
                  />
                )}
                {imageSearch && <ImageSideBar />}
              </aside>
              <main
                className={classnames(styles.results, 'col-12-xs col-9-xl', { ['col-10-xxl']: !imageSearch })}
                id="SearchResultsPage"
                data-testid="SearchResultsPage"
              >
                <div className={classnames('row', styles.filtersViewOptionsWrapper)}>
                  {!!searchTermOverride && (
                    <HeadingH1
                      classList={classnames(styles.searchTermHeading, 'col-12-xs')}
                      page={page}
                      term={searchTermTitle}
                    />
                  )}
                  {!searchTermOverride && (
                    <HeadingH1
                      classList={classnames(styles.searchTermHeading, 'col-12-xs')}
                      page={page}
                      term={searchTerm}
                      category={categoryFilter?.[categoryFilter.length - 1]}
                    />
                  )}
                  <SwitchableFeature feature={TOP_RELEVANT_CATEGORIES}>
                    <TopRelevantCategories topCategories={searchData?.top_categories} />
                  </SwitchableFeature>
                  {!imageSearch && (
                    <>
                      <div className={classnames(styles.searchNav, 'col-12-xs')}>
                        <SearchBreadcrumb
                          categories={categoryData}
                          categoriesParamArray={categoriesParamArray}
                          classList={{ root: styles.searchBreadcrumb }}
                        />
                        {!!lastSubcategory && !!categoryPath && (
                          <CategoryContentPageLink category={lastSubcategory} categoryPath={categoryPath} isHidden />
                        )}
                      </div>
                    </>
                  )}
                  {imageSearch && (
                    <div className="col-12-xs">
                      <h2 className={styles.imageSearchTitle}>
                        {' '}
                        <FormattedMessage defaultMessage="Similar product" id="search.imageResult" />
                      </h2>
                    </div>
                  )}
                  <div className={classnames(styles.divider, 'col-12-xs')} />
                  {!imageSearch && (
                    <>
                      <Link
                        className={classnames(styles.filtersBtnContainer, 'col-12-xs col-5-xl')}
                        to={constructURL('#filters')}
                      >
                        <Button
                          classList={{ root: styles.filtersBtn }}
                          type={PRIMARY}
                          onClick={() => setLoadFeatures(loadFeatures + 1)}
                        >
                          {activeFilters.length === 0 && (
                            <>
                              <FiltersIcon className={styles.filtersIcon} />
                              <p>
                                <FormattedMessage defaultMessage="Filter" id="filters.single" />
                              </p>
                              <b>{numberTotalProducts}</b>
                              <FormattedMessage defaultMessage="Results" id="search.results" />
                            </>
                          )}
                          {activeFilters.length > 0 && (
                            <>
                              <FormattedHTMLMessage
                                defaultMessage="{activeFilters, plural, one {<b>#</b> filter} other {<b>#</b> filters}}"
                                id="filter.pluralFilters"
                                values={{ activeFilters: activeFilters.length }}
                              />
                              <FiltersIcon className={styles.filtersIcon} />
                              <b>{numberTotalProducts}</b>
                              <FormattedMessage defaultMessage="Results" id="search.results" />
                            </>
                          )}
                        </Button>
                      </Link>
                      <ul className={classnames(styles.sortingOptions, 'col-12-xs col-6-m')}>
                        <li>
                          <Link
                            className={classnames(styles.sortingOption, {
                              [styles.sortingOptionSelected]: sortingOption === SORTING_OPTIONS_RELEVANCE,
                            })}
                            to={constructURL(`?sortBy=${SORTING_OPTIONS_RELEVANCE}`)}
                          >
                            <FormattedMessage defaultMessage="Relevance" id="sortOrders.relevance" />
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={classnames(styles.sortingOption, {
                              [styles.sortingOptionSelected]: [
                                SORTING_OPTIONS_PRICE_ASC,
                                SORTING_OPTIONS_PRICE_DESC,
                              ].includes(sortingOption),
                            })}
                            data-testid="sortingOptionPrice"
                            to={constructURL(
                              `?sortBy=${
                                sortingOption !== SORTING_OPTIONS_PRICE_ASC
                                  ? SORTING_OPTIONS_PRICE_ASC
                                  : SORTING_OPTIONS_PRICE_DESC
                              }`
                            )}
                          >
                            <FormattedMessage defaultMessage="Price" id="sortOrders.price" />
                            {[SORTING_OPTIONS_PRICE_ASC, SORTING_OPTIONS_PRICE_DESC].includes(sortingOption) ? (
                              sortingOption === SORTING_OPTIONS_PRICE_ASC ? (
                                <ChevronDownIcon className={styles.sortingOptionDirectionIcon} />
                              ) : (
                                <ChevronUpIcon className={styles.sortingOptionDirectionIcon} />
                              )
                            ) : null}
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={classnames(styles.sortingOption, {
                              [styles.sortingOptionSelected]: sortingOption === SORTING_OPTIONS_NEW,
                            })}
                            to={constructURL(`?sortBy=${SORTING_OPTIONS_NEW}`)}
                          >
                            <FormattedMessage defaultMessage="New" id="sortOrders.new" />
                          </Link>
                        </li>
                        {!!lastSubcategory && !!categoryPath && (
                          <li
                            className={styles.showCategoryLink}
                            onClick={() => setShowMobileCategoryLink(!showMobileCategoryLink)}
                          >
                            <FilterIcon />
                            {showMobileCategoryLink ? (
                              <FormattedMessage defaultMessage="Show less" id="common.showLess" />
                            ) : (
                              <FormattedMessage id="common.showMore" defaultMessage="show More" />
                            )}
                          </li>
                        )}
                      </ul>
                      <div className={classnames('col-12-xs', styles.mobileCategoryLink)}>
                        {!!lastSubcategory && !!categoryPath && (
                          <CategoryContentPageLink
                            category={lastSubcategory}
                            categoryPath={categoryPath}
                            isHidden={!showMobileCategoryLink}
                          />
                        )}
                      </div>
                      <div className={classnames(styles.resultsCounter, 'col-12-xs col-5-xl')}>
                        <FormattedHTMLMessage
                          defaultMessage="{numberResults, plural, one {<b>#</b> result} other {<b>#</b> results}}"
                          id="search.resultsCounter"
                          values={{ numberResults: numberTotalProducts }}
                        />
                      </div>
                      <ViewOptions isTablet={isTablet} viewOption={viewOption} />
                    </>
                  )}
                </div>
                {!!searchRedirect && <SearchRedirectMessage from={searchRedirect?.from} to={searchRedirect?.to} />}
                {/* {(isServer || !searchData) && <ProductPlaceholder view={viewOption} />} */}
                {/* {!searchData && <ProductPlaceholder view={viewOption} />} */}
                {imageSearch && isLoadImageProduct && <div className={styles.spinner}></div>}
                {((searchData?.hits?.hits?.length > 0 && !imageSearch) ||
                  (imageSearch && imageProducts.length > 0 && !isLoadImageProduct)) && (
                  <ProductsList
                    classList={{
                      root: classnames({ [styles.viewOptionsColumns]: viewOption === VIEW_OPTIONS_COLUMNS }),
                    }}
                    isFashionView={isFashionView}
                    view={imageSearch ? VIEW_OPTIONS_TILES : viewOption}
                    page={page}
                    products={imageSearch ? imageProducts : products}
                    isDesktop={isDesktop}
                    selectedProductHash={selectedProductParam}
                    searchData={searchData}
                    videos={productVideosData}
                  />
                )}
                {!imageSearch && (
                  <div className={classnames(styles.paginationAndSummaryContainer)}>
                    <div className={classnames(styles.summary)}>
                      <FormattedHTMLMessage
                        defaultMessage="<b>{startingProductNumber}</b> - {endingProductNumber} from {totalProductNumber}"
                        id="pagination.listSummary"
                        values={{
                          startingProductNumber: (page - 1) * maxProductsPerPage + 1,
                          endingProductNumber:
                            maxProductsPerPage < numberTotalProducts ? page * maxProductsPerPage : numberTotalProducts,
                          totalProductNumber: numberTotalProducts,
                        }}
                      />
                    </div>
                    <div className={classnames(styles.summary)}>
                      <PageSizeDropdown selected={pageSize} />
                    </div>
                    {searchData?.hits?.total?.value > 0 && (
                      <Pagination
                        classList={{
                          root: classnames(styles.pagination),
                        }}
                        currentPage={parseInt(page, 10)}
                        totalPages={totalPages}
                        createPaginatedCanonicalUrl={createPaginatedCanonicalUrl(brandsFilter, categoryPath)}
                      />
                    )}
                  </div>
                )}
              </main>
            </>
          )}
        </div>
      </div>
      {!imageSearch && (
        <SearchBreadcrumb categories={categoryData} categoriesParamArray={categoriesParamArray} isMobile />
      )}
      <RecentlyViewedProducts />
      <NewsletterSection />
      <Footer />
      {featuresData && (
        <FiltersHomeDrawer
          brands={featuresData.brand}
          categories={featuresData?.category?.buckets}
          colors={featuresData?.color?.buckets?.filter((item) => item.key)}
          direction={DRAWER_DIRECTION_LTR}
          filterValues={{
            brandsFilter,
            categoryFilter,
            colorsFilter,
            featuresFilter,
            gendersFilter,
            modelsFilter,
            deliveryFilter,
            sizesFilter,
            priceFrom,
            priceTo,
            shopsFilter,
            sortBy: queryStringParams.get('sortBy'),
          }}
          searchPayload={searchPayload}
          genders={featuresData?.gender?.buckets}
          models={featuresData.model}
          priceRanges={featuresData?.price_ranges?.ranges?.buckets}
          prices={featuresData?.price?.buckets}
          otherFeatures={otherFeatures}
          shops={featuresData.campaign}
          sizes={featuresData?.size?.buckets}
          trigger="filters"
          activeFilters={activeFilters}
        />
      )}
    </React.Fragment>
  );
};

const SearchResultsPageWrapped = ({ staticContext }) => {
  return (
    <SRPWrapper staticContext={staticContext}>
      <SearchResultsPage />
    </SRPWrapper>
  );
};

export default SearchResultsPageWrapped;
